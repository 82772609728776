<template>
  <table class="dataTable" :class="{ 'table-border-right': hasTableBorder }">
    <thead :class="{ 'text-center table-text': hasTableBorder }">
      <tr>
        <th v-for="label in Object.values(labels)" :key="label.index">{{ label }}</th>
        <th v-if="hasButtonColumns" class="dataTable-fix"></th>
      </tr>
    </thead>
    <tbody :class="{ 'text-center': hasTableBorder }">
      <tr v-for="data in dataTable" :key="data.index">
        <td v-for="label in Object.keys(labels)" :key="label.index">
          <router-link
            v-if="data[label] && data[label].routeName"
            class="link"
            :to="{ name: data[label].routeName, params: data[label].params }"
            >{{ data[label].content }}</router-link
          >
          <span v-else-if="data[label] && data[label].class" class="status" :class="data[label].class">{{
            data[label].content
          }}</span>
          <div v-else-if="data[label] && data[label].type === 'image'" class="thumbnail">
            <img v-if="data[label].value" class="thumbnail-img" :src="data[label].value" />
          </div>
          <div v-else-if="data[label] && data[label].isInputType">
            <input
              :class="{ 'form-control text-center w-40': true, 'is-error': errors[data[label].name] }"
              :type="data[label].type"
              :name="data[label].label"
              v-model="data[label].value"
              v-number="data[label].directive"
              @input="handleChange($event, data[label].order)"
            />
          </div>
          <template v-else>{{ data[label] }}</template>
        </td>
        <template v-if="data.linkButtonColumns">
          <td>
            <ul class="listGrid flex-nowrap">
              <li v-for="button in data.linkButtonColumns" :key="button.index" class="listGrid-item">
                <a
                  v-if="button.href"
                  :class="`btn btn-sm ${button.class}`"
                  :href="button.href"
                  target="_blank"
                >
                  {{ button.content }}
                  <i v-if="button.icon" :class="`aikon ${button.icon}`"></i>
                </a>
                <router-link
                  v-else
                  :class="`btn btn-sm ${button.class}`"
                  :to="{ name: button.routeName, params: button.params, query: button.query }"
                >
                  {{ button.content }}
                  <i v-if="button.icon" :class="`aikon ${button.icon}`"></i>
                </router-link>
              </li>
            </ul>
          </td>
        </template>
      </tr>
      <tr v-if="isNoData" class="noData">
        <td colspan="999">{{ noDataMessage }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { COMMON_MESSAGE } from '@/config/message';
import error from '@/mixins/plugin/error';

export default {
  props: {
    dataTable: {
      type: Array,
      required: true,
      default: () => [],
    },
    labels: {
      type: Object,
      required: true,
    },
    hasButtonColumns: {
      type: Boolean,
      default: false,
    },
    unAuthorized: {
      type: Boolean,
      default: false,
    },
    hasTableBorder: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [error],
  computed: {
    isNoData() {
      return this.dataTable.length === 0;
    },
    noDataMessage() {
      return this.unAuthorized ? COMMON_MESSAGE.unAuthorizedTable : COMMON_MESSAGE.noData;
    },
  },
  methods: {
    handleChange(e, order) {
      this.$emit('handle-change', order, e.target.value);
    },
  }
};
</script>

<style scoped>
.icon-vcn {
  margin-left: 5px;
}

.icon-sms {
  margin-left: 8px;
}

.table-border-right {
  border: 1px solid #e1e2eb;
}

.table-border-right td, .table-border-right th {
  border-right: 1px solid #e1e2eb;
}

.table-text {
  color: #4f4e69;
  font-weight: bold;
}

.table-border-right thead tr {
  background: #f7f9fe;
}

.table-border-right tbody tr {
  background: #fff;
}

.w-40 {
  width: 40px !important;
}
</style>
