<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <Table :dataTable="dataTable" :labels="labels"></Table>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog :listLength="parentEventCount" :modulePath="modulePath"></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <Notification />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Notification from '@/components/Notification.vue';
import Table from '@/components/Table.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import { formatLocalString } from '@/helpers/formatData';
import status from '@/mixins/plugin/status';
import event from '@/mixins/module/event';

export default {
  name: 'EventList',
  data: function() {
    return {
      pageName: '親イベント一覧',
      labels: {
        name: '親イベント名',
        children: '子イベント数',
        clients: '事務局名／企業名',
        shops: '店舗数',
      },
      modulePath: 'event/getParentEventList',
    };
  },
  mixins: [status, event],
  components: {
    Notification,
    Table,
    PaginationLog,
  },
  computed: {
    ...mapGetters({
      parentEventList: 'event/parentEventList',
      parentEventCount: 'event/parentEventCount',
    }),
    dataTable() {
      return this.parentEventList.map((item) => {
        return {
          name: this.parentNameContent(item),
          children: formatLocalString(item.aggregate?.count),
          clients: this.clientContent(item.clients),
          shops: formatLocalString(item.aggregate?.shops),
        };
      });
    },
  },
};
</script>
