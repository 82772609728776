import { formatUploadDate } from '@/helpers/formatData';
import { EventConstants } from '@/constants/event';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
    }),
    clientContent: function() {
      return function(list) {
        if (list?.length === 1) {
          return list[0].name;
        }
        if (list?.length > 1) {
          return list[0].name + '、他'
        }
        return '';
      }
    },
    parentNameContent: function() {
      return function(item) {
        return {
          content: item.name,
          routeName: this.$route.name === 'EventList' ? 'EventDashboardParent' : 'SettingEventChild',
          params: { subdomain: item.subdomain },
        }
      }
    },
    childNameContent: function() {
      return function(item) {
        if (item.status.id !== EventConstants.status.unregistered.value && item.isPossibleManage) {
          return {
            content: item.name,
            routeName: !this.isGmoOrOfficeLoginStoreAndStoreGroup ? 'EventDashboardChild' : 'ShopEventDashboardChild',
            params: { subdomain: item.subdomain, directory: item.directory },
          }
        }
        return item.name;
      }
    },
    buttonLinkContent: function() {
      return function(item) {
        let buttonList = [{
          content: '編集',
          class: 'btn-bd-main' + (item.isPossibleManage ? '' : ' is-disabled'),
          routeName: 'SettingEventChildEdit',
          params: { subdomain: item.subdomain, directory: item.directory }
        }];
        if (item.status.id !== EventConstants.status.unregistered.value) {
          buttonList = buttonList.concat([
            { content: 'イベントページ', class: 'btn-white', href: item.eventUrl, icon: 'aikon-external' },
            { content: '加盟店申請フォーム', class: 'btn-white', href: item.formUrl, icon: 'aikon-external' },
          ]);
        }
        return buttonList;
      }
    },
    isEventUnregistered() {
      return this.childEventDetail?.status?.id === EventConstants.status.unregistered.value;
    },
  },
  methods: {
    convertCardImage(data) {
      if (data.cardImageUpload?.url) {
        return {
          type: 'upload',
          imageId: data.cardImageUpload.id,
        }
      } else if (data.cardImageTemplateId) {
        return {
          type: 'selected',
          templateId: data.cardImageTemplateId
        }
      }
      return null;
    },
    convertForm(input) {
      const formData = {
        ...input,
        clients: input.clients?.map(item => item.id),
        otherClients: input.otherClients?.map(item => item.id),
        groups: input.hasGroup ? input.groups: null,
      };
      if (!formData.deleteCategories) {
        formData.deleteCategories = [];
      }
      if (formData.logo?.url) {
        formData.logo = formData.logo.id;
      } else {
        formData.logo = null;
      }
      if (formData.headerLogo?.url) {
        formData.headerLogo = formData.headerLogo.id;
      } else {
        formData.headerLogo = null;
      }
      if (!formData.expirationMonths) {
        formData.sendMailExpireAfterDays = null;
      }
      delete formData.cardImageTemplateId;
      delete formData.cardImageUpload;
      formData.usablePeriod = {
        start: formatUploadDate(formData.usablePeriod.start),
        end: formatUploadDate(formData.usablePeriod.end),
      }
      return formData;
    },
  }
}
