<template>
  <transition name="modal" appear>
    <div v-if="userNotificationList.length > 0" class="notification">
      <header class="notification-header">
        <p class="notification-headline">運営からのお知らせ</p>
        <p class="notification-unread">未読{{ userNotificationList.length }}件</p>
      </header>
      <transition-group class="notification-list" tag="ul" name="notificationItem" appear @before-enter="beforeEnter" @after-enter="afterEnter" @enter-cancelled="afterEnter">
        <li v-for="item in userNotificationList" :key="item.id" :data-index="item.index" class="notification-list-item">
          <p class="notification-list-title">{{ item.message }}</p>
          <ActionButton class="btn btn-sm btn-main notification-list-btn" :handle-submit="() => removeNotification(item.id)" button-text="既読にする" />
        </li>
      </transition-group>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ 
      userNotificationList: 'notification/userNotificationList',
    }),
  },
  methods: {
    removeNotification(id) {
      this.$store.dispatch('notification/markNotificationRead', id);
    },
    beforeEnter(el) {
      el.style.transitionDelay = 0.2 * parseInt(el.dataset.index) + 's';
    },
    afterEnter(el) {
      el.style.transitionDelay = '';
    },
  },
  mounted() {
    this.$store.dispatch('notification/getUserNotificationList');
  }
};
</script>
